.sidebar-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    // border-right: 1px solid #EBEBEB;
    background-color: #e7eef5;
    width: 260px;
    position: absolute;
    left: 0;
    top: 0;
    
    .logo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 10px 0;
    }

    .btn-group {
        padding: 6px 4px;
        margin-bottom: 4px;
        >button {
            margin-bottom: 4px;
        }
    }

    .scrollable {
        transition: width 0.3s cubic-bezier(0.44, 0.9, 0.6, 0.94);
        flex: 1 1 auto;
    }
}

.add-project-modal, .rename-project-modal {
    
    .ant-form-item {
        margin-bottom: 10px;
    }

    .footer {
        text-align: right;
    }
}


.cm-container {
    background-color: white;
    border-radius: 2px;
    box-shadow: rgb(0 0 0 / 37%) 0px 1px 4px 0px;
    padding: 5px;

    .cm-body {
        // padding: 5px;
    }

    .option {
        cursor: pointer;
        display: block;
        border-bottom: 1px solid rgb(240, 240, 240);
        padding: 0 4px;

        &:hover:not(.disabled) {
            background-color: rgb(240, 240, 240);
        }

        &.disabled {
            cursor: default;
            color: #ccc;
        }
    }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;