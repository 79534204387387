.color-setting {

    .current-color-wrapper {
        // border: 1px solid #d9d9d9;
        background-color: #5C5C5C;
        position: relative;
        border-radius: 2px;
        transition: all 0.3s;
        // width: 120px;
        padding: 0 7px;
        cursor: pointer;
        display: flex;
        height: 22px;

        .current-item {
            padding-right: 21px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            flex: 1;
            display: flex;
            align-items: center;

        }

        .ant-select-arrow {
            color: white;
        }

        .color-box {
            border: 1px solid #868686;
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }
    }


}

.color-select-dropdown {
    background-color: rgb(247, 247, 247);
    // min-width: 200px;
    padding: 4px 0;


    .divider {
        width: 100%;
        border-bottom: 1px solid #868686;
    }

    .click-select {
        padding: 0 10px;
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
            background-color: #c9c9c9;
        }
    }

    .color-box {
        border: 1px solid #868686;
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }

    .color-select {
        padding: 6px 10px;

        // display: flex;
        // flex-wrap: wrap;
        .color-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 180px;

            .color-item {
                flex: 0, 0, 20px;
                width: 20px;
                height: 20px;
                padding: 2px 2px;
                cursor: pointer;

                &:hover {
                    padding: 1px;

                    .color-box {
                        width: 19px;
                        height: 19px;
                    }
                }
            }
        }
    }

    .palette-icon {
        margin: 0 6px;
        color: #007ac5;
    }
}

.color-tooltip {
    padding-bottom: 0px;

    .ant-tooltip-arrow {
        bottom: -13px;
    }

    .ant-tooltip-inner {
        color: #000;
        border: 1px solid #aeaeae;
        text-align: center;
        padding: 4px;
    }
}

.color-select-modal {
    &-body {
        display: flex;
        // background-color: #eeeeee;
    }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;