.tn-base {
  position: fixed;
  width: 100vw;
  height: 100vh;
}
.upload-download-panel {
  position: fixed;
  z-index: 1003 !important;
}
.fill-remaining-space {
  flex: 1 1 auto;
}
.tn-base-modal .ant-modal-content .ant-modal-close .ant-modal-close-x {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.tn-base-modal.closable-hidden .ant-modal-close {
  display: none;
}
.tn-base-modal.no-radius .ant-modal-content {
  border-radius: 0;
}
.tn-base-modal.no-padding .ant-modal-content {
  padding: 0;
}
.tn-base-modal .ant-modal-content {
  width: 100%;
  background: #FFF;
  display: inline-flex;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0;
}
.tn-base-modal .ant-modal-content .ant-modal-footer {
  height: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.tn-base-modal .ant-modal-content .ant-modal-footer .btn {
  width: 64px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 4px;
  line-height: 24px;
  color: #FFF;
  font-family: Hiragino Sans GB;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
}
.tn-base-modal .ant-modal-content .ant-modal-footer .btn > span {
  line-height: 1;
}
.tn-base-modal .ant-modal-content .ant-modal-footer .btn.primary {
  background-color: #0088CF !important;
}
.tn-base-modal .ant-modal-content .ant-modal-footer .btn.danger {
  background-color: #FF4D4F !important;
}
.tn-base-modal .ant-modal-content .ant-modal-footer .btn.cancel {
  background-color: #AFAFAF !important;
}
.tn-base-modal .ant-modal-content .ant-modal-body {
  width: 100%;
  height: 100%;
}
.ellipsis {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tn-context-menu-wrapper {
  padding: 0 !important;
  transform: translateY(-4px);
}
.ant-radio {
  position: relative;
  top: 1px;
}
.ant-radio .ant-radio-inner {
  border: 1px solid #333;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #333;
  background-color: #FFF;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
  background-color: #333;
}
.ant-radio-wrapper span.ant-radio + * {
  padding-inline-start: 4px;
  padding-inline-end: 4px;
  color: #000;
  font-family: Hiragino Sans GB;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  background-color: #F0F0F0;
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2);
  opacity: 0.2;
  background-color: #C9CDD4;
  border-radius: 12px;
  transition: background-color 0.3s;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #dcebee;
}
::-webkit-scrollbar-thumb:active {
  background-color: #78b1be;
}
.ant-select-dropdown .rc-virtual-list-scrollbar .rc-virtual-list-scrollbar-thumb {
  background-color: #dae3e5 !important;
}
.ant-select-dropdown .rc-virtual-list-scrollbar .rc-virtual-list-scrollbar-thumb:hover {
  background-color: #b3d1d7 !important;
}
.ant-select-dropdown .rc-virtual-list-scrollbar .rc-virtual-list-scrollbar-thumb:active {
  background-color: #11788f !important;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 2px 6px;
  border-radius: 2px;
  border: 1px solid #c4c4c4;
  background: #f9fafa;
}
.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active {
  border-color: #11788f !important;
  /* 聚焦时的边框颜色 */
  box-shadow: none;
  /* 聚焦时的阴影 */
}
.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: #a5a5a5;
}
.ant-select-dropdown {
  padding: 0px !important;
}
.ant-select-dropdown .ant-select-item {
  padding: 6px 10px 6px 4px;
  border-bottom: 1px solid #E5E6EB;
  background: #ffffff;
  border-radius: 0px;
}
.ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f9fafa;
}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}
::selection {
  background: #11788f !important;
}
html {
  --antd-wave-shadow-color: #11788f !important;
}
.graphic-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.graphic-container .top-nav {
  height: 24px;
  flex-shrink: 0;
  background: var(--, #FFF);
  display: flex;
  flex-direction: row;
}
.graphic-container .top-nav .top-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  border-top: 1px solid #0088CF;
  border-left: 1px solid #0088CF;
  border-bottom: 1px solid #0088CF;
  cursor: pointer;
}
.graphic-container .top-nav .top-logo .arrow-icon {
  padding: 0 4px 0 8px;
}
.graphic-container .top-nav .top-ope {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  border: 1px solid #0088CF;
}
.graphic-container .top-nav .top-ope .ope-icon {
  padding: 0 4px;
  cursor: pointer;
}
.graphic-container .top-nav .top-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100px;
  height: 24px;
  margin-left: auto;
  margin-right: 210px;
  border: 1px solid #0088CF;
}
.graphic-container .top-nav .top-user .head {
  display: flex;
  width: 22px;
  height: 22px;
  margin-right: 4px;
  background-color: yellow;
  margin-left: 1px;
}
.graphic-container .top-nav .top-user .username {
  color: #000;
  font-family: Hiragino Sans GB;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  width: 56px;
  flex-shrink: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.graphic-container .top-nav .top-user .arrow {
  display: flex;
  margin: 0 8px;
}
.graphic-container .menu {
  overflow-x: auto;
  overflow-y: hidden;
  height: 137px;
  flex-shrink: 0;
}
.graphic-container .menu::-webkit-scrollbar {
  height: 4px;
}
.graphic-container .menu .space {
  flex: 1 1 auto;
  background: #F9F9F9;
  margin-left: -1px;
}
.graphic-container .tabs {
  height: 28px;
  flex-shrink: 0;
  background: #004D76;
}
.graphic-container .main {
  display: flex;
  /* 使用 Flexbox 布局 */
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.graphic-container .main .common-horizontal-resizer-container {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.graphic-container .main .draw {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  border-bottom: none;
  flex: 1;
}
.graphic-container .main .side-bar {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
}
.graphic-container .bottom {
  height: 28px !important;
  flex-shrink: 0;
  background: #E3F1F4;
  padding-top: 1px;
  padding-bottom: 1px;
  display: flex;
  z-index: 2;
}
