.properties-setting-container {
    padding: 15px;
    position: relative;
    display: block;
    background-color: #5C5C5C;
    height: 100%;
    width: 300px;

    .manage-header {
        text-align: left;
        margin-bottom: 5px;

        .title {
            color: white;
            font-size: 14px;
        }

        .modal-close {
            position: absolute;
            top: 5px;
            right: 10px;
            color: #c8c8c8;
            transition: color 218ms ease;
            cursor: pointer;
            font-size: 16px;

            &:hover {
                color: #1b9aee;
            }
        }
    }

    .manage-select {
        margin-bottom: 5px;
        color: white;
        background-color: #5C5C5C;
        height: 25px;

        .ant-select {
            color: white;
        }

        .ant-select-selector {
            background-color: #6E6E6E;
            border: 1px solid #333333;
            height: 100%;

            &:hover {
                border-color: #333333;
            }

            .ant-select-selection-item {
                line-height: normal;
            }
        }

        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border-color: #0056F5;
            // box-shadow: 0 0 0 2px #0056F5;
        }

        .ant-select-arrow {
            color: white;
        }
    }

    .ant-collapse {
        border: 0px;
    }

    .ant-collapse>.ant-collapse-item {
        border-bottom: 3px solid #5c5c5c;
        background: #4A4A4A;
    }

    .ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
        padding: 5px 10px;
        color: white;
    }

    .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
        padding-top: 5px;
    }

    .ant-collapse-content {
        color: white;
        background-color: #5c5c5c;
        border-top: 0px;
    }

    .ant-collapse-content>.ant-collapse-content-box {
        padding: 0px 0px 2px 10px;
    }

    .display-left {
        border: 1px solid #525252;
        background-color: #5c5c5c;
        padding-left: 5px;
        height: 25px;
        color: white;
    }

    .display-right {
        border: 1px solid #525252;
        background-color: #5c5c5c;
        color: white;
        padding: 0px;
        height: 25px;

        .ant-select {
            color: white;
            background-color: #5c5c5c !important;
        }

        .ant-select-selector {
            color: white;
            background-color: #5c5c5c !important;
            border: 1px solid #525252 !important;

            &:hover {
                border: 1px solid #525252;
            }
        }

        

        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border: 1px solid #525252;
            box-shadow: none;
            background-color: #6E6E6E;
        }

        .ant-select-arrow {
            color: white;
        }

        .ant-input-number-handler-wrap {
            background: #6A6A6A;
            border: 1px solid #868686;
        }

        .ant-input-number-handler-up-inner,
        .ant-input-number-handler-down-inner {
            color: white;
            font-size: 8px;
        }

        .ant-input-number-handler:active {
            background: #737373;
        }

        .ant-input-number-handler-up-inner {
            margin-top: -8px;
        }

        .ant-input-number-handler-down {
            border-top: none;
        }

        .ant-input-number-handler-up-disabled,
        .ant-input-number-handler-down-disabled {
            cursor: pointer;
        }
    }

    .manage-foot {
        // margin: 20px;
        display: flex;
        justify-content: space-between;
    }

    input[readonly] {
        cursor: default;
        color: #BDBDBD;
    }

}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;