.layer-manage-container {
    padding: 10px;

    .ant-table-thead>tr>th {
        padding: 4px 8px;
        background: #eef1f6;

        .ant-table-column-sorters {
            padding: inherit;
        }
    }

    .ant-table-tbody>tr>td {
        padding: 4px 8px;

        &.ant-table-column-sort {
            background: inherit;
        }
    }

    .ant-table-tbody>tr.ant-table-row:hover>td {
        background-color: initial;
    }

    // .manage-header {
    //     // margin-bottom: 8px !important;
    // }

    .manage-body {
        margin-top: 10px;
        height: 400px;
        overflow: auto;

        .color-box {
            border: 1px solid #868686;
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }

        .color-select {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        .visible-select {
            cursor: pointer;

            >span {
                color: #f5c000;
            }
        }

        .lock-select {
            cursor: pointer;
        }

        .row-selected {
            background-color: #bfe0f8;
        }

        // .ant-select {
        //     color: white;

        //     .ant-select-selector {
        //         background-color: #5c5c5c;
        //         border: 1px solid #525252;

        //         &:hover {
        //             border: 1px solid #525252;
        //         }
        //     }

        //     .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        //         border: 1px solid #525252;
        //         box-shadow: none;
        //         background-color: #6E6E6E;
        //     }

        //     .ant-select-arrow {
        //         color: white;
        //     }

        //     .ant-select-selection-item {
        //         height: 22px !important;
        //     }

        // }

        // .lw-select-option {

        //     .lw-select-box {
        //         background-color: #FEFEFE;
        //         width: 40%;
        //         margin-right: 3px;
        //     }

        //     .lw-select-name {
        //         flex: 1 1 auto;
        //         overflow: hidden;
        //         text-overflow: ellipsis;
        //         color: #FFF;
        //     }
        // }
        .ant-select {
            color: white;

            .ant-select-selector {
                background-color: #5c5c5c !important;
                border: 1px solid #525252;

                &:hover {
                    border: 1px solid #525252;
                }
            }

            .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
                border: 1px solid #525252;
                box-shadow: none;
                background-color: #6E6E6E;
            }

            .ant-select-arrow {
                color: white;
            }

        }

        .lw-select-option {
            width: 100%;
            background-color: #5c5c5c !important;

            .lw-select-box {
                background-color: #FEFEFE;
                width: 40%;
                margin-right: 3px;
            }

            .lw-select-name {
                flex: 1 1 auto;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

}

.layer-context-menu-wrapper {
    background-color: white;
    border-radius: 2px;
    box-shadow: rgb(0 0 0 / 37%) 0px 1px 4px 0px;
    padding: 4px 0px;
}

.layer-context-menu {
    .menu-item {
        padding: 2px 6px;
        user-select: none;

        &:hover {
            background-color: #eaeaea;

        }
    }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;