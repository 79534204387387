
.ucs-select {

    display: flex;
    height: 100%;
    align-items: center;

    &-option {
        display: flex;
        align-items: center;
    
    }

    .ant-select-selection-item{
        height: 22px;
        display: flex;
        align-items: center;
    }


}

.ucs-select-icon {
    margin-right: 8px;
    color: #0168B3;
    display: flex;
    align-items: center;
}

.ucs-select-name {
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;