@primary-color: #0168B3;
.global-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .global-content-view {
        display: flex;
        width: 100%;
        height: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        padding: 0 40px;
        // background-color: #FAFBFC;
        // background-color: #f7f7f7;
    }

    .global-page-route {
        flex-grow: 1;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
        margin-left: 260px;
        padding: 6px;

        display: flex;
        flex-direction: column;
        .top-tools {
            .ant-space.ant-space-horizontal.ant-space-align-center{
                width: 100%;
            }
            .fill-remaining-space-parent{
                display: flex;
            }
            .right, .left {
                display: flex;
                align-items: center;
                margin-right: 24px;
                .action {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    padding: 0 12px;
                    cursor: pointer;
                    transition: all 0.3s;
                    font-size: 22px;
                    color: #7a3f3e;
        
                    &:hover {
                      background: rgba(0, 0, 0, 0.025);
                    }
        
                    &.logout-btn {
                        color: @primary-color;
                        font-size: 18px;
                        >.content {
                            font-size: 14px;
                            margin-left: 4px;
                        }
                    }
        
                }
            }
            margin-bottom: 10px;
        }
        .main-content {
            flex: 1 1 auto;
            height: calc(~'100vh - 106px');
            overflow-y: auto;

            .ant-table-thead>tr>th {
                background: white;
                position: sticky;
                top: 0;
                z-index: 1;
            }
        }



        .file-item {
            display: flex;

            .name {
                cursor: pointer;
                font-size: 14px;
                color: #1F1F1F;

                .input {
                    border: 1px solid #00A4FF;
                }
            }

            .extra-action {
                cursor: pointer;
                // display: none;
                color: #262626;
                font-size: 16px;
                line-height: 22px;

                .menu-btn {
                    border-radius: 4px;
                    padding: 0 4px;
                    transition: background-color 0.3s ease, color 0.3s ease;

                    &:hover {
                        background-color: #e8f5fd;
                        color: #1b9aee;
                    }
                }

                // &.menu-expand {
                //     display: block;
                // }
            }
        }
    }
}

.ant-popover-inner-content {
    padding: 4px;
}

.menu {
    width: 140px;

    .menu-item {
        display: flex;
        padding: 6px 28px;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
            background-color: #F5F5F5;

        }
    }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;