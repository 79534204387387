
.login-container {
    display: flex;
    flex-direction: column;
    background: #f0f2f5;
    height: 100%;
    .title{
        margin-top: 80px;
        text-align: center;
        font-size: 40px;
    }
    .content {
        margin: 20px auto 0;
        padding: 20px;
        border-radius: 6px;
    
        .login-form {
            width: 300px;
            &-forgot {
                float: right;
            }
            &-button {
                width: 100%;
            }
        }
    }

    .footer {
        padding: 0 16px;
        margin: 48px 0 24px;
        text-align: center;
        .copyright {
            color: rgba(0, 0, 0, 0.65);
        }
    }

    .ant-checkbox-inner{
        margin-top: -5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ant-checkbox-inner::after {
        position: relative;
        top: 25%;
        left: -5%;
      }



}







@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;