.draw-panel {
    display: flex;
    width: 100%;
    height: calc(100%);
    // height: 100%;
    overflow: hidden;

    .viewport-item {
        flex: 1 1 auto;
        height: 100%;
        position: relative;
    }

    .viewport {
        position: absolute;
        top: 0px;
        left: 0;
        right: 0px;
        // right: 300px;
        bottom: 0px;

        cursor: none;


        &.active {
            cursor: none;

        }

        &:not(.active) {
            outline: 0px;
        }

        .view-helper {
            cursor: default !important;
            position: absolute;
            right: 0;
            top: 0;
            height: 128px;
            width: 128px;

            .arrow {
                position: absolute;
                // background-color: #fff;
                display: none;
                justify-content: center;
                align-items: center;

                &:hover {
                    .arrow-icon {
                        color: #51a4f1;
                    }
                }

                .arrow-icon {
                    color: #fff;
                    font-size: 16px;
                }

                &.top {
                    height: 16px;
                    width: 32px;
                    left: 48px;
                    top: 12px;
                }

                &.down {
                    height: 16px;
                    width: 32px;
                    left: 48px;
                    bottom: 12px;
                }

                &.right {
                    height: 32px;
                    width: 16px;
                    right: 12px;
                    top: 48px;
                }

                &.left {
                    height: 32px;
                    width: 16px;
                    left: 12px;
                    top: 48px;
                }
            }
        }

        .toolbar {
            position: absolute;
            right: 52px;
            top: 132px;

            // height: 80px;
            // width: 80px;
            .tool-btn {
                background-color: #616060;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                margin-bottom: 4px;

                &:hover {
                    background-color: rgba(17, 120, 143, 1);
                }

                >svg {
                    height: 18px;
                    width: 18px;
                }

                &.is-active {
                    background-color: #646363;
                }
            }



        }

        .displaytype {
            position: absolute;
            left: 97%;
            bottom: 40px;
        }
    }
}

// 选择框的样式，pytha库中SelectControl定义的select-rect这个class，在这里定义属性
.select-rect {
    border: 1px dashed #fff;
    background-color: rgba(25, 189, 19, 0.3);
    position: fixed;

    &.reverse {
        border: 1px solid #fff;
        background-color: rgba(19, 104, 231, 0.3);
    }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;