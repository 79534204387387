.sys-man-top-nav-container {
    height: 46px;
    flex-shrink: 0;
    background-color: #fff;

    display: flex;
    flex-direction: row;
    align-items: center;

    position: relative;

    .top-logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 16px;
        margin-right: 6px;
        cursor: pointer;

        .arrow-icon {
            margin: 0 4px 0 3px;
        }
    }

    .top-ope {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-shrink: 0;
        width: 160px;
        height: 30px;
        border-radius: 2px;
        background: rgba(60, 60, 60, 0.48);

        .ope-text {
            width: 140px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 10px;
            color: #FFF;
            font-family: "Microsoft YaHei";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .ope-icon {
            margin-left: auto;
            margin-right: 10px;
            cursor: pointer;
        }
    }

    .top-work-plane {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .notice {
        display: flex;
        width: 16px;
        align-items: center;
        flex-shrink: 0;
        margin-right: 12px;
        position: relative;
        cursor: pointer;

        .bell {
            position: absolute;
        }

        .unread {
            position: absolute;
            top: -12px;
            right: -3px;
        }
    }

    .top-user {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon-box {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex: 1;

            .icon-svg {
                cursor: pointer;

                display: flex;
                margin-right: 12px;
            }
        }

        .divider {
            width: 1px;
            height: 24px;
            flex-shrink: 0;
            background: #B5B5B5;
        }

        .user-info {
            margin-left: 12px;
            margin-right: 24px;
            width: 84px;
            align-items: center;
            display: flex;
            height: 24px;
            // border: 1px solid #0088cf;
            cursor: pointer;

            .head {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                width: 24px;
                height: 24px;
                margin-right: 4px;

                .img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 8px;
                }
            }

            .username {
                color: #000;
                font-family: Hiragino Sans GB;
                font-size: 12px;
                font-style: normal;
                // font-weight: 300;;
                line-height: 24px;
                margin-left: 4px;
                width: 42px;
                flex-shrink: 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .arrow {
                display: flex;
                margin: 0 0 0 8px;
            }
        }
    }
    
    .menu-group {
        display: flex;
        align-items: center;
        height: 30px;
        background-color: #eaeaea;
        border: 1px solid #989898;
        padding: 0 2px;
        margin-right: 1px;
        margin: 0px 2px;
    }


}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;